import { useMemo, Fragment, ReactElement } from "react";
import { useNavigate } from "react-router-dom";

import styles from "./Breadcrumbs.module.scss";
import routerList from "routes/routerList";

type BreadcrumbLinkType = {
  path: string;
  label: string;
  navigate: (path: string) => void;
}

const BreadcrumbLink = (props: BreadcrumbLinkType) => (
  <button
    className={styles.breadcrumbsLink}
    onClick={() => props.navigate(props.path)}
  >
    {props.label}
  </button>
);

export const createBreadcrumbs = (
  navigate: (path: string) => void,
): Record<string, ReactElement[]> => ({
  "/carriers": [<span key="carriers">Carriers</span>],
  "/carriers/[0-9]+": [
    <BreadcrumbLink
      key={`${routerList.carriers.list}-link`}
      path={routerList.carriers.list}
      label="Carriers"
      navigate={navigate}
    />,
    <span key="carriers-edit">Carrier Edit</span>,
  ],
  "/customers": [<span key="customers">Customers</span>],
  "/customers/[0-9]+": [
    <BreadcrumbLink
      key={`${routerList.customers.list}-link`}
      path={routerList.customers.list}
      label="Customers"
      navigate={navigate}
    />,
    <span key="customers-edit">Customer Edit</span>,
  ],
  "/map": [<span key="map">Map</span>],
  "/locations": [<span key="locations">Locations</span>],
  "/locations/[0-9]+": [
    <BreadcrumbLink
      key={`${routerList.locations.list}-link`}
      path={routerList.locations.list}
      label="Locations"
      navigate={navigate}
    />,
    <span key="locations-edit">Location Edit</span>,
  ],
  "/users": [<span key="users">Users</span>],
  "/users/[0-9]+": [
    <BreadcrumbLink
      key={`${routerList.users.list}-link`}
      path={routerList.users.list}
      label="Users"
      navigate={navigate}
    />,
    <span key="users-edit">User Edit</span>,
  ],
  "/search/.+": [<span key="global-search">Global search</span>],
  "/marketplace-carriers": [
    <span key="marketplace-carriers">Marketplace Carriers</span>,
  ],
  "/routing-guide": [<span key="routing-guide">Routing Guide</span>],
  "/routing-guide/details/[0-9]+": [
    <BreadcrumbLink
      key={`${routerList.routingGuide.list}-link`}
      path={routerList.routingGuide.list}
      label="Routing Guide"
      navigate={navigate}
    />,
    <span key="routing-guide-details">Details</span>,
  ],
  "/coverage-plans": [<span key="coverage-plans">Coverage Plans</span>],
});

const Breadcrumbs = () => {
  const navigate = useNavigate();

  const breadcrumbs = useMemo(() => {
    const crumbsObj = createBreadcrumbs(navigate);
    return Object.keys(crumbsObj).reduce<ReactElement[]>((acc, pattern) => {
      if (new RegExp(pattern).test(window.location.pathname)) {
        return crumbsObj[pattern];
      }
      return acc;
    }, []);
  }, [navigate]);

  return (
    <div className={styles.breadcrumbsContainer}>
      <button
        className={styles.breadcrumbsLink}
        onClick={() => navigate(routerList.dashboard)}
      >
        Dashboard
      </button>
      {breadcrumbs.map((breadcrumb) => (
        <Fragment key={breadcrumb.key}>
          <span className={styles.divider}>{">"}</span>
          {breadcrumb}
        </Fragment>
      ))}
    </div>
  );
};

export default Breadcrumbs;
