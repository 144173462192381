import { useMemo } from "react";
import { Control, Controller, FieldErrors } from "react-hook-form";
import { Dropdown, Form, Input, Segment, TextArea } from "semantic-ui-react";

import { Option } from "../CoveragePlanForm/utils/coveragePlanApiToOptionMappers";
import { convertToFormDateString } from "../CoveragePlanForm/utils/coveragePlanApiToFormMappers";
import { CoveragePlanDto } from "../../../models/dto/CoveragePlanDto";

import styles from "./CarrierCoveragePlanForm.module.scss";
import { integerReplace } from "./utils/integerReplace";

export type CarrierCoveragePlanFormObject = {
  id: string;
  planId: number | null;
  laneId: number | null;
  rate: string;
  equipment: { id: number; name: string } | null;
  coverageAssigned: string;
  note: string;
};

type CarrierCoveragePlanFormProps = {
  closeForm: () => void;
  coveragePlans: CoveragePlanDto[];
  defaultValues: CarrierCoveragePlanFormObject;
  control: Control<CarrierCoveragePlanFormObject>;
  errors: FieldErrors<CarrierCoveragePlanFormObject>;
  setValue: <T>(name: string, value: T) => void;
  planId: CarrierCoveragePlanFormObject["planId"];
  laneId: CarrierCoveragePlanFormObject["laneId"];
};

const CarrierCoveragePlanForm = (props: CarrierCoveragePlanFormProps) => {
  const { planId, laneId, coveragePlans } = props;
  const selectedCoveragePlan = useMemo(
    () => coveragePlans.find((plan) => (plan.id ? plan.id === planId : false)),
    [coveragePlans, planId]
  );

  const selectedCoveragePlanLane = useMemo(
    () =>
      selectedCoveragePlan?.lanes.find((lane) =>
        lane.id ? lane.id === laneId : false
      ),
    [selectedCoveragePlan, laneId]
  );

  const coveragePlanOptions = useMemo(
    () =>
      coveragePlans.reduce<Option<number>[]>((acc, plan) => {
        if (plan.id) {
          acc.push({
            key: plan.id,
            value: plan.id,
            text: plan.customer
              ? `${plan.name} - (${plan.customer.name})`
              : plan.name,
          });
        }

        return acc;
      }, []) ?? [],
    [coveragePlans]
  );

  const coveragePlanLaneOptions = useMemo(
    () =>
      selectedCoveragePlan?.lanes.reduce<Option<number>[]>((acc, lane) => {
        if (lane.id) {
          acc.push({
            key: lane.id,
            value: lane.id,
            text: `${lane.origin} → ${lane.destination}`,
          });
        }

        return acc;
      }, []) ?? [],
    [selectedCoveragePlan]
  );

  const coveragePlanLaneEquipmentOptions = useMemo(
    () =>
      selectedCoveragePlanLane?.equipment.map((equipment) => ({
        key: equipment.id,
        value: equipment.id,
        text: equipment.name,
      })) ?? [],
    [selectedCoveragePlanLane]
  );

  return (
    <Form widths="equal" className={styles.carrierCoveragePlanForm}>
      {/* Hidden field */}
      <Controller
        name="id"
        control={props.control}
        render={(renderProps) => <input {...renderProps} hidden />}
      />

      <Form.Group>
        <Controller
          name="planId"
          control={props.control}
          rules={{ validate: (value) => value > 0 }}
          render={(renderProps) => {
            // Semantic doesn't have a focus method on the Dropdown div but RHF expects it
            renderProps.ref.current.focus = renderProps.ref.current.handleFocus;

            return (
              <Form.Field>
                <label htmlFor={renderProps.name}>Coverage Plan</label>
                <Dropdown
                  {...renderProps}
                  id={renderProps.name}
                  aria-label="Coverage Plan"
                  required
                  selection
                  search
                  clearable
                  placeholder="Select"
                  options={coveragePlanOptions}
                  value={renderProps.value ?? ""}
                  onChange={(_event, { value }) => {
                    renderProps.onChange(value || null);
                    const selectedPlan = coveragePlans.find(
                      (plan) => plan.id === value
                    );
                    if (selectedPlan?.lanes.length === 1) {
                      props.setValue("laneId", selectedPlan.lanes[0].id);
                      props.setValue(
                        "equipment",
                        selectedPlan.lanes[0].equipment.length === 1
                          ? selectedPlan.lanes[0].equipment[0]
                          : null
                      );
                    } else {
                      props.setValue("laneId", null);
                      props.setValue("equipment", null);
                    }
                  }}
                  error={!!props.errors.planId}
                />
              </Form.Field>
            );
          }}
        />

        <Controller
          name="laneId"
          control={props.control}
          rules={{ required: coveragePlanLaneOptions.length > 1 }}
          render={(renderProps) => {
            // Semantic doesn't have a focus method on the Dropdown div but RHF expects it
            renderProps.ref.current.focus = renderProps.ref.current.handleFocus;

            return (
              <Form.Field>
                <label htmlFor={renderProps.name}>Coverage Plan Lane</label>
                <Dropdown
                  {...renderProps}
                  id={renderProps.name}
                  aria-label="Coverage Plan Lane"
                  required={coveragePlanLaneOptions.length > 1}
                  selection
                  search
                  clearable
                  disabled={coveragePlanLaneOptions.length < 2}
                  placeholder="Select"
                  options={coveragePlanLaneOptions}
                  value={renderProps.value ?? ""}
                  onChange={(_event, { value }) => {
                    renderProps.onChange(value || null);
                    const selectedLane = selectedCoveragePlan?.lanes.find(
                      (lane) => lane.id === value
                    );
                    props.setValue(
                      "equipment",
                      selectedLane?.equipment.length === 1
                        ? selectedLane.equipment[0]
                        : null
                    );
                  }}
                  error={!!props.errors.laneId}
                />
              </Form.Field>
            );
          }}
        />
      </Form.Group>

      <h4>Plan Details</h4>

      <Segment>
        <Form.Group>
          <Form.Field>
            <label htmlFor="customer">Customer</label>
            <Input
              name="customer"
              id="customer"
              disabled
              value={selectedCoveragePlan?.customer?.name ?? ""}
            />
          </Form.Field>

          <Form.Field>
            <label htmlFor="targetCompletionDate">Target Completion Date</label>
            <Input
              name="targetCompletionDate"
              id="targetCompletionDate"
              type="date"
              disabled
              value={
                selectedCoveragePlan
                  ? convertToFormDateString(
                      selectedCoveragePlan.targetCompletionDate
                    )
                  : ""
              }
            />
          </Form.Field>
        </Form.Group>

        <Form.Group>
          <Form.Field>
            <label htmlFor="planNote">Custom Plan Notes</label>
            <TextArea
              name="planNote"
              id="planNote"
              value={selectedCoveragePlan?.note ?? ""}
              rows={4}
              disabled
            />
          </Form.Field>
        </Form.Group>
      </Segment>

      <Form.Group>
        <Controller
          name="equipment"
          control={props.control}
          rules={{ required: coveragePlanLaneEquipmentOptions.length > 1 }}
          render={(renderProps) => {
            // Semantic doesn't have a focus method on the Dropdown div but RHF expects it
            renderProps.ref.current.focus = renderProps.ref.current.handleFocus;

            return (
              <Form.Field>
                <label htmlFor={renderProps.name}>Lane Equipment</label>
                <Dropdown
                  {...renderProps}
                  id={renderProps.name}
                  aria-label="Lane Equipment"
                  required={coveragePlanLaneEquipmentOptions.length > 1}
                  selection
                  search
                  clearable
                  disabled={coveragePlanLaneEquipmentOptions.length < 2}
                  placeholder="Select"
                  options={coveragePlanLaneEquipmentOptions}
                  value={renderProps.value?.id ?? ""}
                  onChange={(_event, { value }) => {
                    const equipment = coveragePlanLaneEquipmentOptions.find(
                      (option) => option.value === value
                    );
                    renderProps.onChange(
                      equipment
                        ? { id: equipment.value, name: equipment.text }
                        : null
                    );
                  }}
                  error={!!props.errors.equipment}
                />
              </Form.Field>
            );
          }}
        />

        <Controller
          name="coverageAssigned"
          control={props.control}
          rules={{
            required: true,
            min: 1,
            validate: (value) => Number.isInteger(Number(value)),
          }}
          render={(renderProps) => (
            <Form.Field>
              <label htmlFor={renderProps.name}>
                Expected Equipment Available
              </label>
              <Input
                {...renderProps}
                id={renderProps.name}
                required
                error={!!props.errors.coverageAssigned}
                label={{
                  basic: true,
                  content: selectedCoveragePlan?.frequency ?? "Daily",
                }}
                onChange={(_event, { value }) =>
                  renderProps.onChange(integerReplace(value))
                }
                labelPosition="right"
              />
            </Form.Field>
          )}
        />

        <Controller
          name="rate"
          control={props.control}
          rules={{
            min: 1,
            validate: (value) => Number.isInteger(Number(value)),
          }}
          render={(renderProps) => (
            <Form.Field>
              <label htmlFor={renderProps.name}>Rate</label>
              <Input
                {...renderProps}
                id={renderProps.name}
                error={!!props.errors.rate}
                labelPosition="left"
                onChange={(_event, { value }) =>
                  renderProps.onChange(integerReplace(value))
                }
                label={{ basic: true, content: "$" }}
              />
            </Form.Field>
          )}
        />
      </Form.Group>

      <Form.Group>
        <Controller
          name="note"
          control={props.control}
          render={(renderProps) => {
            return (
              <Form.Field>
                <label htmlFor={renderProps.name}>Note</label>
                <Input
                  {...renderProps}
                  id={renderProps.name}
                  maxLength={500}
                  error={!!props.errors.note}
                />
              </Form.Field>
            );
          }}
        />
      </Form.Group>
    </Form>
  );
};

export default CarrierCoveragePlanForm;
