import { reducer as oidcReducer } from "redux-oidc";

import carriersReducer from "../../pages/Carriers/store/reducers/carriers";
import carrierContactsReducer from "../../pages/Carriers/store/reducers/carrierContacts";
import carrierContactAddressesReducer from "../../pages/Carriers/store/reducers/carrierContactAddresses";
import carrierContactEmailsReducer from "../../pages/Carriers/store/reducers/carrierContactEmails";
import carrierContactPhonesReducer from "../../pages/Carriers/store/reducers/carrierContactPhones";
import mapReducer from "../../pages/Map/store/reducers/mapStats";
import searchReducer from "../../components/Navigation/Search/store/reducer";

import knownLanesReducer from "../../pages/Carriers/store/reducers/knownLanes";

// app internal reducer
import userReducer from "./reducers/user";
import userEditReducer from "../../pages/UserEdit/store/reducer";

import usersReducer from "../../pages/Users/store/reducers/users";
import userAddressesReducer from "../../pages/Users/store/reducers/userAddresses";
import userEmailsReducer from "../../pages/Users/store/reducers/userEmails";
import userPhonesReducer from "../../pages/Users/store/reducers/userPhones";

import entityDocsConfigsReducer from "./reducers/entityDocsConfigs";

import customerContactsReducer from "../../pages/Customers/store/reducers/customerContacts";
import customerContactAddressesReducer from "../../pages/Customers/store/reducers/customerContactAddresses";
import customerContactEmailsReducer from "../../pages/Customers/store/reducers/customerContactEmails";
import customerContactPhonesReducer from "../../pages/Customers/store/reducers/customerContactPhones";

import locationsReducer from "../../pages/Locations/store/reducers/locations";
import locationOperationHoursReducer from "../../pages/Locations/store/reducers/locationOperationHours";
import locationAddressesReducer from "../../pages/Locations/store/reducers/locationAddresses";
import locationEmailsReducer from "../../pages/Locations/store/reducers/locationEmails";
import locationPhonesReducer from "../../pages/Locations/store/reducers/locationPhones";
import { default as locationAttributesReducer } from "../../redux/location/locationAttributesSlice";

import { reducer as countryReducer } from "../../redux/country/countrySlice";
import { reducer as carrierEditReducer } from "../../redux/carrier/carrierSlice";
import { reducer as carrierAttributesReducer } from "../../redux/carrier/carrierAttributesSlice";
import { reducer as carrierShipmentsReducer } from "../../redux/carrier/carrierShipmentsSlice";
import { carrierInvitationSlice } from "../carrier/carrierInvitationSlice";
import { reducer as carrierDocumentsReducer } from "../../redux/carrier/carrierDocumentsSlice";

import { reducer as activitiesReducer } from "../activities/activitiesSlice";

import { reducer as capacityMatchingReducer } from "../../redux/capacityMatching/slice";

import { reducer as customerEditReducer } from "../../redux/customer/customerSlice";
import { reducer as customersListReducer } from "../../redux/customer/customerListSlice";
import { reducer as customerAttributesReducer } from "../../redux/customer/customerAttributesSlice";
import { reducer as customerDocumentsReducer } from "../../redux/customer/customerDocumentsSlice";

import { reducer as carrierFilterReducer } from "../../redux/carrierFilter/carrierFilterSlice";
import { reducer as dashboardReducer } from "../../redux/dashboard/dashboardSlice";
import { reducer as marketplaceReducer } from "../marketplace/marketplaceSlice";
import { reducer as carrierAssociationReducer } from "../../redux/carrierAssociation/carrierAssociationSlice";
import { combineReducers } from "redux";

const rootReducers = () =>
  combineReducers({
    activities: activitiesReducer,
    capacityMatching: capacityMatchingReducer,
    carriers: carriersReducer,
    carrierAttributes: carrierAttributesReducer,
    carrierContacts: carrierContactsReducer,
    carrierContactAddresses: carrierContactAddressesReducer,
    carrierContactEmails: carrierContactEmailsReducer,
    carrierContactPhones: carrierContactPhonesReducer,
    carrierDocuments: carrierDocumentsReducer,
    carrierEdit: carrierEditReducer,
    carrierFilter: carrierFilterReducer,
    carrierInvitation: carrierInvitationSlice.reducer,
    carrierShipments: carrierShipmentsReducer,
    country: countryReducer,
    customerAttributes: customerAttributesReducer,
    customerContacts: customerContactsReducer,
    customerContactAddresses: customerContactAddressesReducer,
    customerContactEmails: customerContactEmailsReducer,
    customerContactPhones: customerContactPhonesReducer,
    customerDocuments: customerDocumentsReducer,
    customerEdit: customerEditReducer,
    customerList: customersListReducer,
    dashboard: dashboardReducer,
    entityDocsConfigs: entityDocsConfigsReducer,
    internalUser: userReducer,
    knownLanes: knownLanesReducer,
    locations: locationsReducer,
    locationAddresses: locationAddressesReducer,
    locationAttributes: locationAttributesReducer,
    locationContacts: locationsReducer,
    locationContactAddresses: locationAddressesReducer,
    locationContactEmails: locationEmailsReducer,
    locationContactPhones: locationPhonesReducer,
    locationEmails: locationEmailsReducer,
    locationOperationHours: locationOperationHoursReducer,
    locationPhones: locationPhonesReducer,
    map: mapReducer,
    marketplace: marketplaceReducer,
    oidc: oidcReducer,
    // app internal reducer
    search: searchReducer,
    users: usersReducer,
    userAddresses: userAddressesReducer,
    userEdit: userEditReducer,
    userEmails: userEmailsReducer,
    userPhones: userPhonesReducer,
    carrierAssociation: carrierAssociationReducer,
  });

export default rootReducers;
